import { useMutation, useQuery } from "react-query";
import { ProductCategoryService } from "../services/productCategory.service";
import {
  CreateProductCategoryDto,
  ProductCategory,
} from "../models/productCategory.model";
import { QueryOpts } from "../models/base.model";

export const useProductCategoryTree = (queryOpts?: QueryOpts) =>
  useQuery(
    "productCategoryTree",
    () => {
      const productCategoryService = new ProductCategoryService();
      return productCategoryService.getProductCategoryTree();
    },
    {
      enabled: queryOpts?.enabled ?? true,
    },
  );

export const useProductCategories = () =>
  useQuery("productCategoriesFlat", () => {
    const productCategoryService = new ProductCategoryService();
    return productCategoryService.getProductCategories();
  });

export const useCreateProductCategory = () =>
  useMutation<ProductCategory, Error, CreateProductCategoryDto>(
    (productCategory) => {
      const productCategoryService = new ProductCategoryService();
      return productCategoryService.addProductCategory(productCategory);
    },
  );

export const useDeleteProductCategory = () =>
  useMutation((slug: string) => {
    const productCategoryService = new ProductCategoryService();
    return productCategoryService.deleteProductCategory(slug);
  });
